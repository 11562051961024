/** @jsx jsx */
import {jsx, css} from '@emotion/react';
import {FC, ReactElement, useCallback, cloneElement, Dispatch, SetStateAction, useEffect, useRef} from 'react';

type IState<S> = [S, Dispatch<SetStateAction<S>>];

export interface PopupProps {
  className?: string;
  button: ReactElement;
  popup: ReactElement | null;
  state: IState<boolean>;
}

export const WithPopup: FC<PopupProps> = ({className, button, popup, state: [open, setOpen]}) => {
  const onToggle = useCallback(
    (e) => {
      if (!e.defaultPrevented) {
        e.preventDefault();
        setOpen((val) => !val);
      }
    },
    [setOpen]
  );
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const node = ref.current;
    if (node === null) return;
    let pass = true;
    const onInside = () => {
      pass = true;
    };
    const onOutside = () => {
      if (pass) {
        pass = false;
      } else {
        setOpen(false);
      }
    };
    node.addEventListener('click', onInside);
    window.addEventListener('click', onOutside);
    return () => {
      node.removeEventListener('click', onInside);
      window.removeEventListener('click', onOutside);
    };
  }, [setOpen]);
  return (
    <div ref={ref} className={className} css={$pos}>
      {cloneElement(button, {
        onClick: onToggle,
        'aria-expanded': open,
        'data-active': open
      })}
      {popup && open && cloneElement(popup, {'data-role': 'popup'})}
    </div>
  );
};

const $pos = css`
  & {
    position: relative;
  }
`;
