import React, {createContext, useContext} from 'react';
import {SavedPlayer} from './components/SavedPlayer';
import {defaultLocale} from './locale';
import {IWidgetProps} from './IWidgetProps';
import {ApiRepositoryProvider} from './hooks/useApiRepository';
import {ConnectionProvider} from './hooks/useConnection';

const WidgetPropsContext = createContext<{props: IWidgetProps}>(null!);

export const useWidgetProps = () => {
  return useContext(WidgetPropsContext).props;
};

const LocaleContext = createContext(defaultLocale);
export const useLocale = () => useContext(LocaleContext);

export default function Root(props: {props: IWidgetProps}) {
  const {record} = props.props;
  return (
    <LocaleContext.Provider value={defaultLocale}>
      <WidgetPropsContext.Provider value={props}>
        <ApiRepositoryProvider lib={props?.props?.lib}>
          <ConnectionProvider entity={record}>
            <SavedPlayer />
          </ConnectionProvider>
        </ApiRepositoryProvider>
      </WidgetPropsContext.Provider>
    </LocaleContext.Provider>
  );
}
