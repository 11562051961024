function hideElement(el: HTMLElement) {
  el.style.position = 'absolute';
  el.style.top = '0px';
  el.style.left = '0px';
  el.style.height = '0px';
  el.style.width = '0px';
}

export function extractImageFromVideo(blob: Blob): Promise<Blob | null> {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas');
    const video = document.createElement('video');

    hideElement(canvas);
    hideElement(video);

    video.muted = true;
    video.src = window.URL.createObjectURL(blob);
    video.addEventListener('loadedmetadata', () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
    });
    video.addEventListener('ended', () => {
      const ctx = canvas.getContext('2d')!;
      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      canvas.toBlob((blob) => {
        window.URL.revokeObjectURL(video.src);
        document.body.removeChild(video);
        document.body.removeChild(canvas);
        resolve(blob);
      });
    });

    document.body.appendChild(canvas);
    document.body.appendChild(video);

    video.play();
  });
}
