/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC, useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {formatTime} from '../../utils/formatTime';
import {IRange, IStore} from '../../StoreModel';

export const ProgressTime: FC<{store: IStore; durationOnly?: boolean}> = observer(({store, durationOnly = false}) => {
  const {currentTime, duration} = store;
  if (durationOnly) {
    return (
      <div css={$time}>
        <b>{formatTime(duration)}</b>
      </div>
    );
  }
  const rangeIndex = currentRangeIndex(store.ranges, currentTime);
  const time = currentTime + (store.ranges[rangeIndex].start - store.start) - durationSum(store.ranges, rangeIndex);
  return (
    <div css={$time}>
      {formatTime(time)}
      {' / '}
      <b>{formatTime(duration)}</b>
    </div>
  );
});

// language=SCSS
const $time = css`
  & {
    color: var(--player-button-color);
    font-size: var(--font-size-sm);
    font-weight: 400;
    padding-top: calc(2rem / var(--bfs));
  }
  & > b {
    font-weight: 500;
  }
`;

export const ProgressBar: FC<{
  store: IStore;
}> = observer(({store}) => {
  const {currentTime, duration} = store;
  const rangeIndex = currentRangeIndex(store.ranges, currentTime);
  const time = currentTime + (store.ranges[rangeIndex].start - store.start) - durationSum(store.ranges, rangeIndex);
  const onChange = useCallback(
    (newValue: number) => {
      const index = store.findIndex(store.start);
      if (index > -1) {
        const rest = newValue * store.duration - (store.ranges[index].start - store.start);
        store.setCurrentTime(durationSum(store.ranges, index) + rest, 'user');
      }
    },
    [store]
  );
  const onClick = useCallback(
    (e) => {
      const {clientX} = e;
      const {left, width} = e.target.getBoundingClientRect();
      onChange(Math.max(clientX - left, 0) / width);
    },
    [onChange]
  );
  return (
    <div css={$position} role={'progress'}>
      <div css={$available} onClick={onClick} />
      <div css={$progress} style={{width: `${(time / duration) * 100}%`}} />
      {store.missing.map((range) => {
        const start = range.start - store.start;
        return (
          <div
            key={range.start}
            css={$missing}
            style={{left: `${(start / duration) * 100}%`, width: `${(range.duration / duration) * 100}%`}}
          />
        );
      })}
    </div>
  );
});

// language=SCSS
const $position = css`
  & {
    display: block;
    width: 100%;
    height: calc(10rem / var(--bfs));

    position: absolute;
    bottom: 100%;
    left: 0;
  }
  & > * {
    transition: height 100ms ease-out;
    height: calc(2rem / var(--bfs));
  }
  &:hover > * {
    height: calc(8rem / var(--bfs));
  }
`;

// language=SCSS
const $available = css`
  & {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    backdrop-filter: blur(15px);
    width: 100%;
    background: var(--player-button-color);
    opacity: 0.4;
  }
`;

const $progress = css`
  & {
    pointer-events: none;
    position: absolute;
    bottom: 0;
    background: var(--player-button-color);
    opacity: 0.8;
    transition: width 200ms ease-out, height 100ms ease-out;
  }
`;

// language=SCSS
const $missing = css`
  & {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    width: 0;
    background: var(--error-color);
    opacity: 0.3;
  }
`;

const currentRangeIndex = (ranges: IRange[], currentTime: number) => {
  let index = 0;
  let rest = currentTime;
  while (index < ranges.length - 1) {
    rest = rest - ranges[index].duration;
    if (rest <= 0) {
      break;
    }
    index++;
  }
  return index;
};

const durationSum = (ranges: IRange[], index: number) => {
  if (index >= ranges.length) {
    return 0;
  }
  let sum = 0;
  let current = 0;
  while (current < index) {
    sum += ranges[current].duration;
    current++;
  }
  return sum;
};
