import {Instance, types} from 'mobx-state-tree';

const PlayerModel = types
  .model({
    volume: types.optional(types.number, 0),
    isMuted: types.optional(types.boolean, true),
    isPlaying: types.optional(types.boolean, false),
    isFullscreen: types.optional(types.boolean, false),
    isWaiting: types.optional(types.boolean, false),
    isError: types.optional(types.boolean, false),
    naturalSize: types.optional(types.model({width: types.number, height: types.number}), {width: 0, height: 0})
  })
  .actions((self) => {
    return {
      setVolume(value: number, source: IChangeSource) {
        if (0 <= value && value <= 1) {
          self.volume = value;
        }
      },
      setIsMuted(value: boolean, source: IChangeSource) {
        self.isMuted = value;
      },
      setIsPlaying(value: boolean, source: IChangeSource) {
        self.isPlaying = value;
      },
      setIsFullscreen(value: boolean, source: IChangeSource) {
        self.isFullscreen = value;
      },
      setIsWaiting(value: boolean) {
        self.isWaiting = value;
      },
      setIsError(value: boolean) {
        self.isError = value;
      },
      setNaturalSize(width: number, height: number, source: IChangeSource) {
        if (width >= 0 && height >= 0) {
          self.naturalSize = {width, height};
        }
      }
    };
  });

const RangeModel = types
  .model({
    start: types.number,
    end: types.number
  })
  .views((instance) => {
    return {
      get duration() {
        return instance.end - instance.start;
      },
      includes(time: number) {
        return instance.start <= time && time <= instance.end;
      }
    };
  });

const RangeStoreModel = types
  .model({
    ranges: types.refinement(
      types.array(RangeModel),
      (snapshot) => typeof snapshot !== 'undefined' && snapshot.length > 0
    )
  })
  .views((self) => {
    const includes = (time: number) => (r: IRange) => r.includes(time);
    return {
      get start() {
        if (self.ranges.length === 0) {
          throw Error('Unexpected state');
        } else {
          return self.ranges[0].start;
        }
      },
      get end() {
        if (self.ranges.length === 0) {
          throw Error('Unexpected state');
        } else {
          return self.ranges[self.ranges.length - 1].end;
        }
      },
      includes(time: number) {
        return self.ranges.some(includes(time));
      },
      findIndex(time: number) {
        return self.ranges.findIndex(includes(time));
      },
      get missing() {
        return missingRanges(self.ranges);
      }
    };
  })
  .views((instance) => {
    return {
      get duration() {
        return instance.end - instance.start;
      }
    };
  });

const missingRanges = (ranges: IRange[]): IRange[] => {
  const result: IRange[] = [];
  for (let i = 0; i < ranges.length - 1; i++) {
    result.push(RangeModel.create({start: ranges[i].end, end: ranges[i + 1].start}));
  }
  return result.filter((r) => r.duration > 1);
};

export type IChangeSource = 'api' | 'user';
export type IStore = Instance<typeof StoreModel>;
export type IRange = Instance<typeof RangeModel>;

export const StoreModel = types
  .compose(PlayerModel, RangeStoreModel, types.model({currentTime: types.optional(types.number, 0)}))
  .actions((self) => {
    return {
      setCurrentTime(seconds: number, source: IChangeSource) {
        if (seconds >= 0) {
          self.currentTime = Math.min(seconds, self.end);
        } else {
          throw Error('Arg "seconds" should be >= 0');
        }
      },
      setDuration(seconds: number) {
        self.ranges[self.ranges.length - 1].end = seconds;
      }
    };
  })
  .views((self) => {
    return {
      get absoluteCurrentTime() {
        return self.start + self.currentTime;
      }
    };
  });
