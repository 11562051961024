export function formatTime(time: number, displayHours = false, inverted = false) {
  const hours = getHours(time);
  const mins = getMinutes(time);
  const secs = getSeconds(time);

  let hoursString = '';
  if (displayHours || hours > 0) {
    hoursString = `${hours}:`;
  }

  return `${inverted && time > 0 ? '-' : ''}${hoursString}${format(mins)}:${format(secs)}`;
}

const format = (value: number) => `0${value}`.slice(-2);
const getHours = (value: number) => Math.floor((value / 60 / 60) % 60);
const getMinutes = (value: number) => Math.floor((value / 60) % 60);
const getSeconds = (value: number) => Math.floor(value % 60);
