/** @jsx jsx */
import {jsx} from '@emotion/react';
import {makeButton} from './makeButton';

export const Forward = makeButton((props) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 16.0015L8 22.0015V10.0015L16.5 16.0015ZM25.5 16.0015L17 22.0015V10.0015L25.5 16.0015Z"
        fill="#959DA8"
      />
    </svg>
  );
});
