/** @jsx jsx */
import {css, jsx, keyframes} from '@emotion/react';
import {FC} from 'react';
import {ERROR} from '../error';
import {useLocale} from '../Root';
import {EmptyIcon} from './EmptyIcon';

const rgbToHex = (rgb: [number, number, number]) => {
  return `#${rgb
    .map((x) => {
      const hex = x.toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    })
    .join('')}`;
};

const getColorValues = (rawVar: string) => JSON.parse(`[${getComputedStyle(document.body).getPropertyValue(rawVar)}]`);

const colors = {
  error: rgbToHex(getColorValues('--error-color-raw')),
  warning: rgbToHex(getColorValues('--warning-color-raw')),
  info: rgbToHex(getColorValues('--primary-color-raw'))
};

export const ErrorMessage: FC<{error: ERROR | undefined}> = ({error}) => {
  const locale = useLocale();
  let blinking = false;
  let color;
  switch (error) {
    case ERROR.record_is_uploading:
      blinking = true;
      color = colors.info;
      break;
    case ERROR.preview_not_found:
      color = colors.warning;
      break;
    default:
      color = colors.error;
  }
  return error ? (
    <div css={$error} data-blinking={blinking}>
      <EmptyIcon key={color} color={color} />
      <span>{locale.errors[error]}</span>
    </div>
  ) : null;
};

const $blinking = keyframes`
from {
  opacity: 0.5;
}

to {
  opacity: 1;
}`;

// language=SCSS
const $error = css`
  & {
    pointer-events: none;

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  & > svg {
    position: absolute;
    top: calc(50% - 20rem / var(--bfs));
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    max-height: calc(100% - 60rem / var(--bfs));
  }
  & > span {
    position: absolute;
    top: 70%;
    font-size: var(--font-size-lg);
    font-weight: 500;
    color: var(--player-button-color);
  }
  &[data-blinking='true'] > svg {
    animation: ${$blinking} 900ms ease-out alternate infinite;
  }
`;
