export interface IDisposer {
  (): void;
}

export interface IDisposers {
  add: (disposer: IDisposer) => void;
  remove: (disposer: IDisposer) => void;
  flush: () => void;
}

export function createDisposers(): IDisposers {
  const ds = new Set<IDisposer>();
  let flushed = false;
  return {
    add: (disposer: IDisposer) => {
      if (flushed) {
        disposer();
      } else {
        ds.add(disposer);
      }
    },
    remove: (disposer: IDisposer): void => {
      ds.delete(disposer);
    },
    flush: () => {
      flushed = true;
      [...ds].reverse().forEach((d) => d());
    }
  };
}
