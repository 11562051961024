/** @jsx jsx */
import {jsx} from '@emotion/react';
import {makeButton} from './makeButton';

export const EnterFullscreen = makeButton((props) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M22.667 15.9998V9.33317H16.0003V6.6665H25.3337L25.3235 15.9998H22.667Z" fill="#959DA8" />
      <path
        d="M9.33366 15.9998L9.33366 22.6665H16.0003V25.3332L6.66699 25.3332L6.67716 15.9998H9.33366Z"
        fill="#959DA8"
      />
    </svg>
  );
});
