/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {ProgressSpinner} from 'primereact/progressspinner';
import {FC} from 'react';
import {IStore} from '../StoreModel';
import {observer} from 'mobx-react-lite';

// language=SCSS
const containerCss = css`
  & {
    pointer-events: none;
    position: absolute;
    top: calc(15rem / var(--bfs));
    left: calc(15rem / var(--bfs));
  }
  //&[data-visible='true'] {
  //  opacity: 1;
  //  svg {
  //    animation-play-state: running;
  //  }
  //}
  //&[data-visible='false'] {
  //  opacity: 0;
  //  svg {
  //    animation-play-state: paused;
  //  }
  //}
`;

// language=SCSS
const spinnerCss = css`
  body &.p-progress-spinner {
    pointer-events: none;
    display: block;
    font-size: calc(32rem / var(--bfs));
    width: 1em !important;
    height: 1em !important;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.1);
  }
  body & > svg {
    font-size: inherit;
    width: 1em !important;
    height: 1em !important;
  }
  body & > svg > circle {
    stroke-width: calc(5rem / var(--bfs));
  }
`;

export const Spinner: FC<{store: IStore; force?: boolean}> = observer(({store, force = false}) => {
  return store.isWaiting || force ? (
    <div css={containerCss}>
      <ProgressSpinner css={spinnerCss} />
    </div>
  ) : null;
});
