/** @jsx jsx */
import {jsx} from '@emotion/react';
import {makeButton} from './makeButton';

export const Backward = makeButton((props) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 16.0015L15 10.0015V22.0015L6.5 16.0015ZM24 22.0015L15.5 16.0015L24 10.0015V22.0015Z"
        fill="#959DA8"
      />
    </svg>
  );
});
