import React, {useEffect, createContext, ReactNode, useContext, useState, useReducer, useMemo} from 'react';
import {fiwareOptions} from '@netvision/lib-api-gateway';
import {CameraMediaResources} from '@netvision/lib-api-repo/dist/src/types';
import {useApiRepository} from '../hooks/useApiRepository';
import {isUndefined, mapKeys, memoize} from 'lodash-es';
import {ERROR} from '../error';

const streamHeaders: Array<[string, string]> = [
  ['Fiware-Service', fiwareOptions.service],
  ['Fiware-ServicePath', fiwareOptions.servicePath]
];

// time in seconds!
export interface ITimeline {
  start: number;
  end: number;
}

export type TVideo = ICameraRecord | IVideoFile;

export interface IRecord<T extends string> {
  type: T;
  id: string;
  duration: number;
  snapshotUrl: string;
  streamUrl: string;
}

export interface ICameraRecord extends IRecord<'Record'> {
  from: number;
  cameraId: string;
  status?: 'NotStarted' | 'InProgress' | 'Loaded' | 'Failed';
  dvrTimelines?: Array<ITimeline>;
}

export interface IVideoFile extends IRecord<'File'> {
  extension: string;
  dateCreated: string;
  owner: string;
  parentFolderId: string;
  resourceId: string;
  size: number;
}

const createRanges = (start = 0, end = 0) => [{start, end}];

type ConnectionProviderProps = {
  children: ReactNode;
  entity: TVideo | undefined;
};

type ConnectionConfig = {
  connection: {
    error: ERROR | undefined;
    loading: boolean;
    streamResources: null | CameraMediaResources;
    streamHeaders: Array<[string, string]>;
    ranges: ITimeline[];
  };
  fetchPreview: (url: string) => Promise<void | Blob | null>;
  record?: TVideo;
};

const ConnectionContext = createContext<ConnectionConfig>({
  connection: {
    error: undefined,
    loading: true,
    streamResources: null,
    streamHeaders,
    ranges: createRanges()
  },
  fetchPreview: memoize((url: string) => {
    return fetch(url, {
      mode: 'cors',
      headers: streamHeaders
    })
      .then((res) => (res.status === 200 ? res.blob() : null))
      .catch((error) => console.error(error));
  })
});

export const useConnection = () => useContext(ConnectionContext);

export const ConnectionProvider = ({children, entity}: ConnectionProviderProps) => {
  const {api} = useApiRepository();
  const [isCameraRecord] = useState(entity?.type === 'Record');
  const [record, setRecord] = useState<TVideo>();
  const [connection, setConnection] = useReducer(
    (connection: ConnectionConfig['connection'], payload: Partial<ConnectionConfig['connection']>) => ({
      ...connection,
      ...payload
    }),
    {...useConnection().connection}
  );

  if (!('getEntitiesWithGlobalBatch' in api)) {
    throw new Error('api-repo method "getEntitiesWithGlobalBatch" in not defined');
  }

  const cameraRange = useMemo(() => {
    return !record || !('dvrTimelines' in record) ? createRanges() : record.dvrTimelines;
  }, [record]);

  const fileRange = useMemo(() => {
    return !record ? createRanges() : createRanges(0, record.duration);
  }, [record]);

  const connectionError = useMemo(() => {
    if (!isCameraRecord) return undefined;
    if (!record) {
      return connection.loading ? undefined : ERROR.record_not_found;
    } else if ('status' in record && !isUndefined(record.status)) {
      if (['NotStarted', 'InProgress'].includes(record.status)) return ERROR.record_is_uploading;
      if (record?.status !== 'Loaded') return ERROR.record_status_is_unsupported;
      return undefined;
    }
    return undefined;
  }, [record, connection.loading, isCameraRecord]);

  const fixRecord = (entity: ICameraRecord): Required<ICameraRecord> => {
    if (entity.status === undefined) {
      entity.status = 'Loaded';
    }

    if (!entity.dvrTimelines) {
      return {
        ...entity,
        dvrTimelines: createRanges()
      } as Required<ICameraRecord>;
    }

    return {
      ...entity,
      dvrTimelines: entity.dvrTimelines.length
        ? entity.dvrTimelines.map((r) => mapKeys(r, (_, key) => key.toLowerCase()) as unknown as ITimeline)
        : [{start: entity.from, end: entity.from + entity.duration}]
    } as Required<ICameraRecord>;
  };

  useEffect(() => {
    setRecord(entity?.type === 'Record' ? fixRecord(entity) : entity);
  }, [entity]);

  useEffect(() => {
    setConnection({
      ranges: isCameraRecord ? cameraRange : fileRange,
      error: isCameraRecord ? connectionError : undefined
    });

    entity?.id &&
      api
        .getEntitiesWithGlobalBatch(
          {id: entity.id, type: record?.type || 'Record'},
          ['streamUrl', 'snapshotUrl', 'snapshotStreamUrl'],
          'player-saved'
        )
        .then((streamResources) => {
          setConnection({streamResources, loading: false});
        })
        .catch((error) => console.error(error));
  }, [record, api, cameraRange, connectionError, entity, fileRange, isCameraRecord]);

  return (
    <ConnectionContext.Provider value={{connection, record, fetchPreview: useConnection().fetchPreview}}>
      {children}
    </ConnectionContext.Provider>
  );
};
