import {ERROR} from './error';

const errors: Record<ERROR, string> = {
  record_not_found: 'Запись не найдена',
  record_is_not_loaded: 'Запись в процессе сохранения',
  record_status_is_unsupported: 'Ошибка при сохранении записи',
  preview_not_found: 'Превью отсутствует',
  stream_not_found: 'Не удалось загрузить запись'
};

export const defaultLocale = {
  controls: {
    refresh: 'Обновить',
    play: 'Старт',
    pause: 'Пауза',
    volume: 'Громкость',
    forward: '+ {{}} секунд',
    backward: '- {{}} секунд',
    enterFullscreen: 'Полноэкранный режим',
    exitFullscreen: 'Покинуть полноэкранный режим'
  },
  errors
};
