/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {makeButton} from './makeButton';

export const Volume = makeButton((props) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.18526 18.5303C3.25075 16.9728 3.25075 15.027 4.18526 13.4695C4.49005 12.9615 5.00752 12.6179 5.59397 12.5341L8.70197 12.0901C9.10394 12.0327 9.46242 11.8068 9.68765 11.4689L10.1771 10.7347C10.5648 10.1532 10.7586 9.86249 10.9742 9.60109C11.7346 8.67884 12.7291 7.97801 13.8534 7.57202C14.1721 7.45695 14.5111 7.3722 15.1891 7.2027L16.0065 6.99835C16.4599 6.88499 16.6867 6.82831 16.8651 6.88386C17.0201 6.93208 17.1521 7.03518 17.2365 7.17381C17.3337 7.33348 17.3337 7.56718 17.3337 8.03458V23.9652C17.3337 24.4326 17.3337 24.6663 17.2365 24.826C17.1521 24.9646 17.0201 25.0677 16.8651 25.1159C16.6867 25.1715 16.4599 25.1148 16.0065 25.0014L15.1891 24.7971C14.5111 24.6276 14.1721 24.5428 13.8534 24.4277C12.7291 24.0218 11.7346 23.3209 10.9742 22.3987C10.7586 22.1373 10.5648 21.8465 10.1771 21.265L9.68765 20.5308C9.46241 20.193 9.10393 19.9671 8.70197 19.9096L5.59398 19.4656C5.00752 19.3819 4.49005 19.0383 4.18526 18.5303Z"
        fill="#959DA8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0073 10.5787C20.3978 10.1882 21.031 10.1882 21.4215 10.5787C22.8519 12.0091 23.659 13.9468 23.667 15.9697C23.6749 17.9925 22.8831 19.9366 21.464 21.3782C21.0766 21.7717 20.4435 21.7767 20.0499 21.3893C19.6563 21.0019 19.6513 20.3687 20.0387 19.9751C21.0876 18.9096 21.6729 17.4727 21.667 15.9776C21.6611 14.4824 21.0645 13.0502 20.0073 11.9929C19.6168 11.6024 19.6168 10.9693 20.0073 10.5787Z"
        fill="#959DA8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.169 7.75031C24.5596 7.35978 25.1927 7.35978 25.5833 7.75031C27.7599 9.92697 28.9881 12.8757 29.0003 15.9539C29.0124 19.0322 27.8074 21.9905 25.648 24.1842C25.2606 24.5778 24.6274 24.5828 24.2338 24.1954C23.8402 23.8079 23.8353 23.1748 24.2227 22.7812C26.0119 20.9635 27.0103 18.5124 27.0003 15.9618C26.9902 13.4113 25.9726 10.968 24.169 9.16452C23.7785 8.77399 23.7785 8.14083 24.169 7.75031Z"
        fill="#959DA8"
      />
    </svg>
  );
});
