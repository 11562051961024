// language=SCSS
import {css} from '@emotion/react';
import {MutableRefObject, useEffect, useState} from 'react';

// language=SCSS
export const relativeCss = css`
  & {
    position: relative;
    width: 100%;
  }
`;

// language=SCSS
export const gradientCss = css`
  & {
    position: absolute;
    bottom: 0;
    left: 0;
    padding-top: calc(45rem / var(--bfs));
    height: calc(200rem / var(--bfs));
    width: 100%;
    background-image: var(--player-controls-gradient);
  }
`;

// language=SCSS
export const visibleCss = css`
  &[data-visible] {
    transition: opacity var(--transition-duration) ease;
  }
  &[data-visible='false'] {
    opacity: 0;
  }
  &[data-visible='true'] {
    opacity: 1;
  }
`;

// language=SCSS
export const panelPositionCss = css`
  & {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
`;

export const useHideControls = (ref: MutableRefObject<HTMLElement | null>, initial = false) => {
  const [visible, setVisible] = useState(initial);
  useEffect(() => {
    const node = ref.current;
    if (node === null) {
      return undefined;
    }
    let timeout: any = 0;
    const refreshTimer = () => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = 0;
      }
      timeout = setTimeout(hide, 5000);
    };
    const show = () => {
      setVisible(true);
      refreshTimer();
    };
    const hide = () => {
      clearTimeout(timeout);
      setVisible(false);
    };
    node.addEventListener('mouseenter', show);
    node.addEventListener('mousemove', show);
    node.addEventListener('mouseleave', hide);
    return () => {
      node.removeEventListener('mouseenter', show);
      node.removeEventListener('mousemove', show);
      node.removeEventListener('mouseleave', hide);
    };
  }, [ref]);
  return visible;
};
