/** @jsx jsx */
import {jsx, css} from '@emotion/react';
import {FC, ReactNode, useCallback} from 'react';
import {Play} from './buttons/Play';
import {Pause} from './buttons/Pause';
import {VolumeControl} from './VolumeControl';
import {useLocale, useWidgetProps} from '../../Root';
import {ExitFullscreen} from './buttons/ExitFullscreen';
import {EnterFullscreen} from './buttons/EnterFullscreen';
import {IStore} from '../../StoreModel';
import {observer} from 'mobx-react-lite';
import {Forward} from './buttons/Forward';
import {Backward} from './buttons/Backward';
import {ProgressBar, ProgressTime} from './ProgressControl';

export type IAvailableControl =
  | 'play'
  | 'volume'
  | 'fullscreen'
  | 'progress-bar'
  | 'progress-time'
  | 'duration'
  | 'forward'
  | 'backward';

export const ControlPanel: FC<
  {store: IStore; controls: IAvailableControl[]; head?: ReactNode} & JSX.IntrinsicElements['div']
> = observer(({store, controls, children, head, ...rest}) => {
  const controlsSet = new Set(controls);
  return (
    <div {...rest} css={$panel}>
      {controlsSet.has('progress-bar') && <ProgressBar store={store} />}
      <section>
        {controlsSet.has('backward') && store.isFullscreen && <BackwardBtn store={store} />}
        {controlsSet.has('play') && <PlayPause store={store} />}
        {controlsSet.has('forward') && store.isFullscreen && <ForwardBtn store={store} />}
        {controlsSet.has('duration') && <ProgressTime store={store} durationOnly={!controlsSet.has('progress-time')} />}
        {head}
      </section>
      <section>
        {children}
        <div />
        {controlsSet.has('volume') && <Volume store={store} />}
        {controlsSet.has('fullscreen') && <FullScreen store={store} />}
      </section>
    </div>
  );
});

// language=SCSS
const $panel = css`
  & {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: calc(15rem / var(--bfs));
  }
  & > section {
    display: flex;
    align-items: center;
  }
  & > section > * {
    margin-right: calc(20rem / var(--bfs));
  }
  & > section > *:last-child {
    margin-right: 0;
  }
`;

const PlayPause: FC<{store: IStore}> = observer(({store}) => {
  const locale = useLocale();
  const handlePlay = useCallback(() => {
    store.setIsPlaying(true, 'user');
  }, [store]);
  const handlePause = useCallback(() => {
    store.setIsPlaying(false, 'user');
  }, [store]);
  return store.isPlaying ? (
    <Pause tooltipAlign={'left'} tooltip={locale.controls.pause} onClick={handlePause} />
  ) : (
    <Play tooltipAlign={'left'} tooltip={locale.controls.play} onClick={handlePlay} />
  );
});

const Volume: FC<{store: IStore}> = observer(({store}) => {
  const {controls} = useLocale();
  const onChange = useCallback(
    (value) => {
      store.setVolume(value, 'user');
    },
    [store]
  );
  const toggleMute = useCallback(() => {
    store.setIsMuted(!store.isMuted, 'user');
  }, [store]);
  return (
    <VolumeControl
      muted={store.isMuted}
      toggleMute={toggleMute}
      tooltipAlign={'right'}
      tooltip={controls.volume}
      value={store.volume}
      onChange={onChange}
    />
  );
});

const FullScreen: FC<{store: IStore}> = observer(({store}) => {
  const locale = useLocale();
  const handleEnter = useCallback(() => {
    store.setIsFullscreen(true, 'user');
  }, [store]);
  const handleExit = useCallback(() => {
    store.setIsFullscreen(false, 'user');
  }, [store]);
  return store.isFullscreen ? (
    <ExitFullscreen tooltipAlign={'right'} tooltip={locale.controls.exitFullscreen} onClick={handleExit} />
  ) : (
    <EnterFullscreen tooltipAlign={'right'} tooltip={locale.controls.enterFullscreen} onClick={handleEnter} />
  );
});

const BackwardBtn: FC<{store: IStore}> = ({store}) => {
  const {forwardAmount = 5} = useWidgetProps();
  const locale = useLocale();
  const onBackward = useCallback(() => {
    if (forwardAmount > 0) {
      store.setCurrentTime(Math.max(store.currentTime - forwardAmount, 0), 'user');
    } else {
      console.error(new Error('Arg "seconds" should be > 0'));
    }
  }, [store, forwardAmount]);
  return (
    <Backward
      onClick={onBackward}
      tooltipAlign={'left'}
      tooltip={locale.controls.backward.replace('{{}}', forwardAmount.toString())}
    />
  );
};

const ForwardBtn: FC<{store: IStore}> = ({store}) => {
  const {forwardAmount = 5} = useWidgetProps();
  const locale = useLocale();
  const onForward = useCallback(() => {
    if (forwardAmount > 0) {
      store.setCurrentTime(Math.min(store.currentTime + forwardAmount, store.duration), 'user');
    } else {
      console.error(new Error('Arg "seconds" should be > 0'));
    }
  }, [store, forwardAmount]);
  return (
    <Forward
      onClick={onForward}
      tooltipAlign={'left'}
      tooltip={locale.controls.forward.replace('{{}}', forwardAmount.toString())}
    />
  );
};
