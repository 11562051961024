import {IStore} from '../StoreModel';
import Plyr from 'plyr';
import 'plyr.css';
import {createDisposers} from '../utils/disposers';
import {onAction} from 'mobx-state-tree';
import {reaction} from 'mobx';
import {IAvailableControl} from './controls/ControlPanel';
import {genId} from '../utils/genId';

export const initPlyr = (
  video: HTMLVideoElement,
  container: HTMLElement,
  ratio: 'fit' | string | null | undefined,
  store: IStore,
  controls: IAvailableControl[]
) => {
  const controlsSet = new Set(controls);
  container.parentElement!.id = container.parentElement!.id || genId();

  const options = {
    ratio: ratio === 'fit' || ratio === null ? undefined : ratio,
    clickToPlay: false,
    controls: [],
    muted: !controlsSet.has('volume'),
    fullscreen: {container: `#${container.parentElement!.id}`} as any
  };
  const plyr = new Plyr(video, options);
  plyrRemoveDbClick(plyr);

  const disposers = createDisposers();

  if (ratio === 'fit') {
    disposers.add(setFit(video));
  }
  if (typeof ratio === 'string' && ratio !== 'fit') {
    disposers.add(removeAspectRatioInFullscreen(plyr, ratio, store));
  }

  // set natural size
  plyr.once('loadedmetadata', () => {
    store.setNaturalSize(video.videoWidth, video.videoHeight, 'api');
  });

  // Bind waiting
  const onWaiting = () => {
    store.setIsWaiting(true);
  };
  const onCanPlay = () => {
    store.setIsWaiting(false);
  };
  if (plyr.autoplay) {
    onWaiting();
  }
  plyr.on('waiting', onWaiting);
  plyr.on('stalled', onWaiting);
  plyr.on('canplay', onCanPlay);
  disposers.add(() => {
    plyr.off('waiting', onWaiting);
    plyr.off('stalled', onWaiting);
    plyr.off('canplay', onCanPlay);
  });

  // Bind play/pause
  if (controlsSet.has('play')) {
    const onPlay = () => {
      store.setIsPlaying(true, 'api');
    };
    const onPause = () => {
      store.setIsPlaying(false, 'api');
    };
    plyr.on('play', onPlay);
    plyr.on('pause', onPause);
    disposers.add(() => {
      plyr.off('play', onPlay);
      plyr.off('pause', onPause);
    });
    disposers.add(
      onAction(store, (actionCall) => {
        const {name, args} = actionCall;
        if (name === 'setIsPlaying' && args !== undefined) {
          const [value, source] = args;
          if (source === 'user') {
            if (value) {
              plyr.play();
            } else {
              plyr.pause();
            }
          }
        }
      })
    );
  }

  // Bind currentTime
  if ((['progress-bar', 'progress-time', 'forward', 'backward'] as const).some((v) => controlsSet.has(v))) {
    const onTimeUpdate = () => {
      store.setCurrentTime(plyr.currentTime, 'api');
    };
    onTimeUpdate();
    plyr.on('timeupdate', onTimeUpdate);
    disposers.add(() => {
      plyr.off('timeupdate', onTimeUpdate);
    });
    const finish = () => {
      store.setCurrentTime(store.duration, 'api');
    };
    plyr.on('ended', finish);
    disposers.add(() => {
      plyr.off('ended', finish);
    });
    disposers.add(
      onAction(store, (actionCall) => {
        const {name, args} = actionCall;
        if (name === 'setCurrentTime' && args !== undefined) {
          const [currentTime, source] = args;
          if (source === 'user') {
            plyr.currentTime = currentTime;
          }
        }
      })
    );
  }

  // Bind volume
  if (controlsSet.has('volume')) {
    const onVolumeChange = () => {
      store.setVolume(plyr.volume, 'api');
      store.setIsMuted(plyr.muted, 'api');
    };
    onVolumeChange();
    plyr.on('volumechange', onVolumeChange);
    disposers.add(() => {
      plyr.off('volumechange', onVolumeChange);
    });
    disposers.add(
      onAction(store, (actionCall) => {
        const {name, args} = actionCall;
        if (name === 'setVolume' && args !== undefined) {
          const [volume, source] = args;
          if (source === 'user') {
            plyr.volume = volume;
          }
        }
      })
    );
    disposers.add(
      onAction(store, (actionCall) => {
        const {name, args} = actionCall;
        if (name === 'setIsMuted' && args !== undefined) {
          const [muted, source] = args;
          if (source === 'user') {
            plyr.muted = muted;
          }
        }
      })
    );
  }

  // Bind fullscreen
  if (controlsSet.has('fullscreen')) {
    const onEnter = () => store.setIsFullscreen(true, 'api');
    const onExit = () => store.setIsFullscreen(false, 'api');
    if (plyr.fullscreen.active) {
      onEnter();
    } else {
      onExit();
    }
    plyr.on('enterfullscreen', onEnter);
    plyr.on('exitfullscreen', onExit);
    disposers.add(() => {
      plyr.off('enterfullscreen', onEnter);
      plyr.off('exitfullscreen', onExit);
    });
    disposers.add(
      onAction(store, (actionCall) => {
        const {name, args} = actionCall;
        if (name === 'setIsFullscreen' && args !== undefined) {
          const [value, source] = args;
          if (source === 'user') {
            if (value) {
              plyr.fullscreen.enter();
            } else {
              plyr.fullscreen.exit();
            }
          }
        }
      })
    );
  }

  plyr.destroy = new Proxy(plyr.destroy, {
    apply(target, thisArg, argArray) {
      disposers.flush();
      return Reflect.apply(target, thisArg, argArray);
    }
  });
  return plyr;
};

function plyrRemoveDbClick(plyr: any) {
  const l = plyr?.eventListeners?.find(
    (_l: {element: HTMLElement; callback: () => void; options: {}; type: string}) => _l.type === 'dblclick'
  );
  if (l) {
    l.element.removeEventListener(l.type, l.callback, l.options);
  }
}

function removeAspectRatioInFullscreen(player: Plyr, ratio: string, store: IStore) {
  const onResize = () => {
    player.ratio = `${window.innerWidth}:${window.innerHeight}`;
  };

  const onEnter = () => {
    onResize();
    window.addEventListener('resize', onResize);
  };

  const onExit = () => {
    window.removeEventListener('resize', onResize);
    player.ratio = ratio;
  };

  if (player.fullscreen.active) {
    onEnter();
  } else {
    onExit();
  }

  return reaction(
    () => store.isFullscreen,
    (value) => (value ? onEnter() : onExit())
  );
}

const className = genId();
const style = document.createElement('style');
style.innerText = `.${className}{height:100%;width:100%}`;
document.head.appendChild(style);
const setFit = (video: HTMLVideoElement) => {
  const disposers = createDisposers();
  let current: HTMLElement = video;
  while (current.className !== 'single-spa-parcel-container' || current === document.body) {
    const el = current;
    el.classList.add(className);
    disposers.add(() => {
      el.classList.remove(className);
    });
    if (el.parentElement) {
      current = el.parentElement;
    }
  }
  current.classList.add(className);
  disposers.add(() => {
    current.classList.remove(className);
  });
  return disposers.flush;
};
