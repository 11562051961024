/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {makeButton} from './makeButton';

export const Muted = makeButton(() => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.18483 18.5305C3.25032 16.9729 3.25032 15.0272 4.18483 13.4697C4.48962 12.9617 5.0071 12.6181 5.59355 12.5343L8.70154 12.0903C9.10351 12.0329 9.46199 11.807 9.68723 11.4691L10.1767 10.7349C10.5644 10.1534 10.7582 9.86266 10.9737 9.60126C11.7342 8.679 12.7287 7.97818 13.853 7.57219C14.1717 7.45712 14.5107 7.37237 15.1887 7.20286L16.0061 6.99852C16.4595 6.88515 16.6862 6.82847 16.8647 6.88402C17.0197 6.93225 17.1517 7.03535 17.2361 7.17398C17.3333 7.33365 17.3333 7.56735 17.3333 8.03475V23.9654C17.3333 24.4328 17.3333 24.6665 17.2361 24.8261C17.1517 24.9648 17.0197 25.0679 16.8647 25.1161C16.6862 25.1716 16.4595 25.115 16.0061 25.0016L15.1887 24.7972C14.5107 24.6277 14.1717 24.543 13.853 24.4279C12.7287 24.0219 11.7342 23.3211 10.9737 22.3988C10.7582 22.1374 10.5644 21.8467 10.1767 21.2652L9.68723 20.531C9.46199 20.1932 9.10351 19.9672 8.70154 19.9098L5.59355 19.4658C5.0071 19.382 4.48962 19.0384 4.18483 18.5305Z"
        fill="#959DA8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2929 11.2929C20.6834 10.9024 21.3166 10.9024 21.7071 11.2929L25 14.5858L28.2929 11.2929C28.6834 10.9024 29.3166 10.9024 29.7071 11.2929C30.0976 11.6834 30.0976 12.3166 29.7071 12.7071L26.4142 16L29.7071 19.2929C30.0976 19.6834 30.0976 20.3166 29.7071 20.7071C29.3166 21.0976 28.6834 21.0976 28.2929 20.7071L25 17.4142L21.7071 20.7071C21.3166 21.0976 20.6834 21.0976 20.2929 20.7071C19.9024 20.3166 19.9024 19.6834 20.2929 19.2929L23.5858 16L20.2929 12.7071C19.9024 12.3166 19.9024 11.6834 20.2929 11.2929Z"
        fill="#959DA8"
      />
    </svg>
  );
});
