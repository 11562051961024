/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useWidgetProps} from '../Root';
import {Spinner} from './Spinner';
import {observableBox} from '../utils/observable';
import {initPlyr} from './initPlyr';
import {ControlPanel, IAvailableControl} from './controls/ControlPanel';
import {gradientCss, panelPositionCss, relativeCss, useHideControls, visibleCss} from './common';
import {genId} from '../utils/genId';
import {createDisposers} from '../utils/disposers';
import {destroy} from 'mobx-state-tree';
import {extractImageFromVideo} from '../utils/extractImageFromVideo';
import {StoreModel} from '../StoreModel';
import {ErrorMessage} from './ErrorMessage';
import {ERROR} from '../error';
import {reaction} from 'mobx';
import {ZoomZone} from '@netvision/front-utils/lib/react/components/ZoomZone';
import {useConnection} from '../hooks/useConnection';
import {isString} from 'lodash-es';
import Hls from 'hls.js';

export const SavedPlayer = () => {
  const {className, ratio = '16:9'} = useWidgetProps();
  const {
    connection: {error, loading, streamHeaders, streamResources, ranges},
    fetchPreview,
    record
  } = useConnection();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const videoNodeRef = useRef<HTMLDivElement | null>(null);
  const [activated, setActivated] = useState(false);
  const [controls, setControls] = useState<IAvailableControl[]>([]);
  const controlsVisible = useHideControls(containerRef);
  const [streamError, setStreamError] = useState(false);
  const [posterVideoObs] = useState(() => observableBox(''));
  const [posterImageObs] = useState(() => observableBox(''));
  const [posterLoading, setPosterLoading] = useState(false);
  const [posterError, setPosterError] = useState(false);
  const [video, setVideo] = useState<HTMLVideoElement | null>(null);
  const getStore = useCallback(() => StoreModel.create({ranges}), [ranges]);
  const [store, setStore] = useState(getStore);

  const revokePosterImage = () => {
    URL.revokeObjectURL(posterImageObs.get());
  };
  const revokePosterVideo = () => {
    URL.revokeObjectURL(posterVideoObs.get());
  };

  const getPreview = async (attempt?: number) => {
    let mounted = true;
    setPosterError(false);
    setPosterLoading(true);
    const queryUrl =
      String(streamResources?.snapshotUrl || streamResources?.snapshotStreamUrl) +
      (attempt ? `&attempt=${attempt}` : '');

    try {
      let blob = await fetchPreview(queryUrl);
      if (mounted && blob) {
        revokePosterVideo();
        posterVideoObs.set(URL.createObjectURL(blob));
        blob = await extractImageFromVideo(blob);
        if (blob) {
          revokePosterImage();
          posterImageObs.set(URL.createObjectURL(blob));
          setPosterLoading(false);
          return;
        }
      } else {
        attempt = !attempt ? 2 : attempt + 1;
        if (attempt > 5) {
          throw new Error('File preview cannot be fetched');
        }
        getPreview(attempt);
      }
    } catch (error) {
      console.error(error);
      setPosterLoading(false);
    }
  };

  useEffect(() => () => destroy(store), [store]);

  useEffect(() => {
    streamResources?.snapshotUrl || streamResources?.snapshotStreamUrl
      ? getPreview()
      : (() => {
          if (!loading) {
            setPosterLoading(false);
            setPosterError(true);
          }
        })();
  }, [streamResources, posterVideoObs, posterImageObs, fetchPreview]);

  useEffect(() => {
    const container = containerRef.current;
    const videoNode = videoNodeRef.current;
    if (container === null || videoNode === null) {
      setVideo(null);
      return undefined;
    }
    const d = createDisposers();
    const video = document.createElement('video');
    videoNode.appendChild(video);
    d.add(() => {
      videoNode.textContent = '';
    });

    setVideo(video);

    const store = getStore();
    setStore(store);

    // init stream
    if (activated && streamHeaders) {
      video.id = genId();
      video.autoplay = true;
      store.setIsPlaying(true, 'api');

      d.add(
        posterImageObs.subscribe((poster) => {
          video.poster = poster;
        })
      );

      const hls = new Hls({
        xhrSetup: (xhr) => {
          streamHeaders.forEach(([name, value]) => {
            xhr.setRequestHeader(name, value);
          });
        }
      });
      hls.attachMedia(video);

      !record?.duration &&
        hls.on(Hls.Events.BUFFER_APPENDED, (_, data) => {
          // @ts-ignore
          const duration = data.timeRanges.video?.end(0);
          if (duration) {
            store.setDuration(duration);
          }
        });

      hls.on(Hls.Events.ERROR, (event, data) => {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.MEDIA_ERROR:
              console.error('fatal media error encountered, try to recover');
              hls.recoverMediaError();
              break;
            default:
              console.error('Fatal HLS error encountered ' + data.type);
              setStreamError(true);
              setActivated(false);
              break;
          }
        }
      });

      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        if (streamResources && 'streamUrl' in streamResources && isString(streamResources?.streamUrl)) {
          hls.loadSource(streamResources.streamUrl);
        }
      });

      d.add(() => {
        hls.detachMedia();
        hls.destroy();
      });

      const controls: IAvailableControl[] = [
        'play',
        'forward',
        'backward',
        'progress-bar',
        'progress-time',
        'duration',
        'volume',
        'fullscreen'
      ];

      setControls(controls);
      const plyr = initPlyr(video, container, ratio, store, controls);

      d.add(() => {
        plyr.destroy();
      });
    }
    // init preview
    else {
      d.add(
        posterVideoObs.subscribe((src) => {
          video.src = src;
        })
      );
      const controls: IAvailableControl[] = streamHeaders === null ? [] : ['play', 'duration', 'fullscreen'];
      setControls(controls);
      const plyr = initPlyr(video, container, ratio, store, controls);
      if (streamHeaders !== null) {
        d.add(
          reaction(
            () => store.isPlaying,
            (value, r) => {
              if (value) {
                setActivated(true);
                setStreamError(false);
                r.dispose();
              }
            }
          )
        );
      }
      d.add(() => {
        plyr.destroy();
      });
      return d.flush;
    }
    return d.flush;
    // @ts-ignore; remove when lib-api-repo will be updated
  }, [streamResources, getStore, activated, streamHeaders, posterImageObs, posterVideoObs, ratio, record]);

  let finalError = error;

  if (streamError) {
    finalError = finalError || ERROR.stream_not_found;
  }

  if (!activated && posterError) {
    finalError = finalError || ERROR.preview_not_found;
  }

  return (
    <div ref={containerRef} className={className} css={relativeCss}>
      <div ref={videoNodeRef} />
      {controls.length > 0 && <div css={[gradientCss, visibleCss]} data-visible={controlsVisible} />}
      <ErrorMessage error={finalError} />
      <Spinner store={store} force={loading || posterLoading} />
      {!finalError && activated && video && (
        <div css={$zoom}>
          <ZoomZone elements={[video]} />
        </div>
      )}
      {controls.length > 0 && (
        <ControlPanel
          store={store}
          controls={controls}
          css={[panelPositionCss, visibleCss]}
          data-visible={controlsVisible}
        />
      )}
    </div>
  );
};

const $zoom = css`
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: absolute;
`;
